import React, { useEffect, useState } from "react";
import WelfareService from "../../services/service/welfare";
import { useParams } from "react-router-dom";
import TextBanner from "../../components/banner/TextBanner";
import ScholarshipCard from "../../components/card/ScholarshipCard";

export default function AcknowledgeWelfare() {
  let [welfareData, setWelfareData] = useState([]);

  let { lang } = useParams();

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getWelfareData();
  }, []);

  const getWelfareData = async () => {
    try {
      let res = await WelfareService.getWelfare();

      setWelfareData(res);
    } catch {
      console.error();
    }
  };

  const bannerText = [
    { name: "service", href: "/service" },
    { name: "Service Request", href: "/service/service_request" },
  ];

  return (
    <div id="acknowledge" className="pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={"แจ้งรับสวัสดิการ"}
        text_en={"Service Request"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* บริการ */}
          <div className="flex flex-wrap gap-[18px] mt-[38px]">
            {welfareData?.map((item, i) => (
              <ScholarshipCard
                data={item}
                key={i}
                lang={lang}
                page="service/service_request"
                isImageBox
              />
            ))}
          </div>

          {welfareData && welfareData.length <= 0 && (
            <p className="text-[23px] text-center mt-[40px]">
              {lang === "th"
                ? "ไม่พบข้อมูลที่ค้นหา..."
                : "Not found any news ..."}
            </p>
          )}
          <div className="mt-[75px] mb-[300px]"></div>
        </div>
      </div>
    </div>
  );
}
