import http from "../http-common";
import NavbarModel from "../../models/components/navbar";

// Navbar APIs
// eslint-disable-next-line
export default new (class NavbarService {
  // Get all members
  async getNavbar() {
    return await http
      .get("/template-head-navbars?populate=*")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((navbar) => NavbarModel.get(navbar))
        );

        return data.sort((a, b) => a.order - b.order);
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
