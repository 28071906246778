import http from "./../http-common";
import BannerModel from "../../models/components/banner";

// Navbar APIs
// eslint-disable-next-line
export default new (class BannerService {
  // Get all members
  async getBanner(page) {
    return await http
      .get(`/banners/${page}?populate=deep`)
      .then(async (response) => {
        const data = await BannerModel.get(response.data.data);

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
