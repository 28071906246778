function getVideoImage(videoUrl) {
  if (videoUrl.indexOf("/watch?v=") !== -1) {
    const videoId = videoUrl.split("v=")[1];
    const ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
      return videoId.substring(0, ampersandPosition);
    }
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  } else if (videoUrl.indexOf("/youtu.be/") !== -1) {
    const videoId = videoUrl.split(".be/")[1];
    const ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
      return videoId.substring(0, ampersandPosition);
    }
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  }
  return ``;
}

export default getVideoImage;
