import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PageBanner from "../../components/banner/PageBanner";
import CustomButton from "../../components/button/CustomButton";

export default function AboutUs() {
  let { lang } = useParams();
  const history = useHistory();

  const bannerText = [{ name: "About Us", href: "/abouts" }];

  const aboutUsButton = [
    {
      name: "ทำเนียบนายกสมาคม",
      href: "/association",
    },
    {
      name: "วิสัยทัศน์และพันธกิจ",
      href: "/mission",
    },
    {
      name: "วัตถุประสงค์",
      href: "/objective",
    },
    {
      name: "ประวัติสมาคมฯ",
      href: "/history",
    },
    {
      name: "โครงสร้างคณะกรรมการ",
      href: "/broading",
    },
    {
      name: "ระเบียบสวัสดิการสมาชิก",
      href: "/member_rule",
    },
  ];

  return (
    <div id="about-us" className="pt-[135px] lg:pt-[0px] pb-[150px]">
      <PageBanner
        text_th={"เกี่ยวกับสมาคมฯ"}
        text_en={"About Us"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          <div className="flex flex-col lg:grid lg:grid-cols-3 mt-[49px] gap-[40px] md:gap-[80px]">
            <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-[20px] h-fit">
              {aboutUsButton.map((item, i) => (
                <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/${lang}/abouts${item.href}`);
                  }}
                  key={i}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  {item.name}
                </div>
              ))}
            </div>
            {/* <div className="col-span-1 items-start">
              <div className="items-start w-full flex flex-col gap-[12px]">
                <div>
                  <p className="text-[22px] xl:text-[26px] text-left">
                    ตรวจสอบสมาชิกภาพ
                  </p>
                  <h1 className="text-[28px] xl:text-[32px] text-left">
                    หรือ <span className="text-gold">เข้าสู่ระบบ</span>
                  </h1>
                </div>
                <div className="justify-start py-[17px] px-[24px] flex flex-col gap-[10px] rounded-[12px] border-[1px] border-lightGray2">
                  <p className="text-[16px] xl:text-[18px] text-left">
                    หมายเลขสมาชิก หรือ เลขบัตรประชาชน
                  </p>
                  <input className="w-full placeholder-lightGray2 text-[16px] rounded-[8px] border-[1px] border-lightGray2 py-[14px] px-[18px]" />
                  <CustomButton
                    hasIcon
                    text={"ตรวจสอบ"}
                    onClick={() => {
                      window.scroll(0, 0);
                      history.push(`/${lang}/service/member_checking`);
                    }}
                  />
                </div>
                <div>
                  <p className="text-gold text-[20px] xl:text-[22px]">{`หรือ สมัครสมาชิก >>`}</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
