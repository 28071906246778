// eslint-disable-next-line
export default new (class BannerModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;

    if (data.attributes.Banner && data.attributes.Banner.image.data) {
      model.banner = data.attributes.Banner;
    } else {
      model.banner = [];
    }

    return model;
  }
})();
