import http from "../http-common";
import WelfareModel from "../../models/service/welfare";

// eslint-disable-next-line
export default new (class WelfareService {
  async getWelfare() {
    return await http
      .get("/welfares?populate=deep")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((welfare) => WelfareModel.get(welfare))
        );

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getWelfareBySlug(slug) {
    return await http
      .get(`/welfares?filters[Slug][$eq]=${slug}`)
      .then(async (response) => {
        const id = response.data.data[0].id;

        const news = await http
          .get(`/welfares/${id}?populate=deep`)
          .then(async (response) => {
            const data = await WelfareModel.get(response.data.data);
            return data;
          });

        return news;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
