import React, { useEffect } from "react";
import PageBanner from "../../components/banner/PageBanner";
// import ServiceService from "../../services/service";
// import BannerService from "../../services/banner";
import { useHistory, useParams } from "react-router-dom";
import CustomButton from "../../components/button/CustomButton";

export default function Service() {
  //   let [serviceData, setServiceData] = useState([]);
  //   let [serviceBanner, setServiceBanner] = useState([]);

  let { lang } = useParams();
  const history = useHistory();

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // getServiceData();
    // getServiceBanner();
  }, []);

  //   const getServiceData = async () => {
  //     try {
  //       let res = await ServiceService.getService();
  //       setServiceData(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  //   const getServiceBanner = async () => {
  //     try {
  //       let res = await BannerService.getBanner(1);
  //       setServiceBanner(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  const bannerText = [{ name: "service", href: "/service" }];

  return (
    <div id="service" className="pt-[135px] lg:pt-[0px]">
      <PageBanner
        text_th={"บริการสมาชิก"}
        text_en={"Service"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* บริการ */}
          <div className="mt-[54px] mb-[300px]">
            <h1 className="text-[28px] xl:text-[32px] text-left">
              {lang === "th" ? "บริการสมาชิก" : "Service"}
            </h1>

            <div className="flex flex-col lg:grid lg:grid-cols-3 gap-[50px]">
              <div className="mt-[27px] col-span-2 grid grid-cols-1 md:grid-cols-2 gap-[20px] h-fit">
                <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/${lang}/service/service_request`);
                  }}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  แจ้งรับสวัสดิการ
                </div>
                <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/${lang}/service/documents`);
                  }}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  วารสาร/จุลสารวิชาการ
                </div>
                <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/${lang}/service/apply_scholarship`);
                  }}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  สมัครทุนการศึกษา
                </div>
                <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/${lang}/service/apply_trainning`);
                  }}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  สมัครประชุม/สัมมนา
                </div>
              </div>

              <div className="col-span-1 items-start">
                <div className="items-start w-full flex flex-col gap-[12px]">
                  <div>
                    <p className="text-[22px] xl:text-[24px] text-left">
                      ตรวจสอบสมาชิกภาพ
                    </p>
                    <h1 className="text-[28px] xl:text-[32px] text-left">
                      หรือ <span className="text-gold">เข้าสู่ระบบ</span>
                    </h1>
                  </div>
                  <div className="justify-start py-[17px] px-[24px] flex flex-col gap-[10px] rounded-[12px] border-[1px] border-lightGray2">
                    <p className="text-[16px] xl:text-[18px] text-left">
                      หมายเลขสมาชิก หรือ เลขบัตรประชาชน
                    </p>
                    <input className="w-full placeholder-lightGray2 text-[16px] rounded-[8px] border-[1px] border-lightGray2 py-[14px] px-[18px]" />
                    <CustomButton
                      hasIcon
                      text={"ตรวจสอบ"}
                      onClick={() => {
                        window.scroll(0, 0);
                        history.push(`/${lang}/service/memberChecking`);
                      }}
                    />
                  </div>
                  <div>
                    <p className="text-gold text-[20px] xl:text-[22px]">{`หรือ สมัครสมาชิก >>`}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
