import React, { useEffect, useState } from "react";
// import AllButton from "../../components/button/AllButton";
import TextBanner from "../../components/banner/TextBanner";
import Pagination from "../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { awardSize, socialSize } from "../../configs/pageSize";
import Datepicker from "react-tailwindcss-datepicker";
import ScholarshipCard from "../../components/card/ScholarshipCard";

export default function PridesSearch(props) {
  //   let [newsData, setNewsData] = useState([]);
  //   let [meta, setMeta] = useState({});
  //   let [newsBanner, setNewsBanner] = useState([]);

  let { lang } = useParams();

  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(
    props.page === "award" ? awardSize : socialSize
  );
  const [keyword, setKeyword] = useState("");
  // eslint-disable-next-line
  const [sort, setSort] = useState("desc");

  const [date, setDate] = useState({
    startDate: new Date(
      new Date().getFullYear() - 4,
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // getNewsBanner();

    // eslint-disable-next-line
  }, []);

  //   useEffect(() => {
  //     getNewsData();

  //     // eslint-disable-next-line
  //   }, [keyword, date, sort, page, pageSize]);

  //   const getNewsData = async () => {
  //     try {
  //       let [res, meta] = await NewsService.getNews({
  //         ...(keyword && { keyword: keyword }),
  //         startDate: date.startDate,
  //         endDate: date.endDate,
  //         sort: sort,
  //         page: page,
  //         pageSize: pageSize,
  //       });
  //       setNewsData(res);
  //       setMeta(meta);
  //     } catch {
  //       console.error();
  //     }
  //   };

  //   const getNewsBanner = async () => {
  //     try {
  //       let res = await BannerService.getBanner(2);
  //       setNewsBanner(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  const handleKeywordChange = (newKeyword) => {
    setKeyword(newKeyword);
    // getNewsData();
  };

  const handleDateChange = (newValue) => {
    setDate(newValue);
  };

  const bannerText = () => {
    if (props.page === "award")
      return [
        { name: "Works and Honor", href: "/honor" },
        { name: "Awards", href: "/honor/awards" },
      ];
    else if (props.page === "socialService")
      return [
        { name: "Works and Honor", href: "/honor" },
        { name: "Social Service Activities", href: "/honor/social_activities" },
      ];
    return [];
  };

  const pageText = () => {
    if (props.page === "award")
      return [
        {
          text_th: "รางวัล และ ผลงานพยาบาล",
          text_en: "Awards",
          title_th: "รางวัล/ผลงานพยาบาล",
          title_en: "Awards",
        },
      ];
    else if (props.page === "socialService")
      return [
        {
          text_th: "กิจกรรมบริการสังคม",
          text_en: "Social Service Activities",
          title_th: "กิจกรรมบริการสังคม",
          title_en: "Social Service Activities",
        },
      ];
    return [];
  };

  return (
    <div id="news-all" className="pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={pageText()[0].text_th}
        text_en={pageText()[0].text_en}
        // banner={newsBanner.banner}
        bannerText={bannerText()}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* ข่าวสาร */}
          <div id="news-section" className="pt-[54px] pb-[48px]">
            <h1 className="text-[28px] xl:text-[32px] text-left capitalize">
              {lang === "th" ? pageText()[0].title_th : pageText()[0].title_en}
            </h1>

            <div className="my-[20px] flex flex-col items-start justify-between gap-[20px] lg:gap-[40px]">
              <div className="w-full gap-[20px] flex flex-col items-center lg:flex-row">
                <div className="relative w-full">
                  <input
                    defaultValue={keyword}
                    onChange={(e) => handleKeywordChange(e.target.value)}
                    placeholder={
                      lang === "th" ? "ค้นหาจาก Keyword" : "Finding by Keyword"
                    }
                    className="w-full font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-[25px] cursor-pointer absolute text-darkGray top-[12px] right-[15px] pointer-events-none"
                  />
                </div>
                <div className="flex items-center gap-[50px] w-full">
                  <h1 className="text-[20px] whitespace-nowrap">
                    {lang === "th" ? "ช่วงเวลา" : "Time Range"}
                  </h1>
                  <div className="date-picker relative w-full">
                    <Datepicker value={date} onChange={handleDateChange} />
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-[25px] cursor-pointer absolute text-darkGray top-[14px] left-[15px] pointer-events-none"
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  `flex flex-col lg:flex-row items-center gap-[20px] w-full ` +
                  (props.page === "award" ? "justify-between" : "justify-end")
                }
              >
                {props.page === "award" && (
                  <div>
                    <p className="text-[16px] xl:text-[18px] flex items-center gap-[22px] items-center flex-wrap">
                      หมวดหมู่ :{" "}
                      <span className="flex items-center gap-[22px] items-center flex-wrap">
                        {[...Array(3)].map((item, i) => (
                          <p
                            key={i}
                            className={
                              `text-brown cursor-pointer ` +
                              (i === 0 && "underline text-gold")
                            }
                          >
                            ข่าวประชาสัมพันธ์
                          </p>
                        ))}
                      </span>
                    </p>
                  </div>
                )}

                <div className="flex items-center gap-[50px]">
                  <h1 className="text-[20px] whitespace-nowrap">
                    {lang === "th" ? "เรียงลำดับ" : "Sort By"}
                  </h1>
                  <select
                    name="ordering"
                    className="w-fit font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                    value={sort}
                    onChange={(event) => setSort(event.target.value)}
                  >
                    <option value="desc">
                      {lang === "th" ? "ล่าสุด - เก่าสุด" : "Latest to Oldest"}
                    </option>
                    <option value="asc">
                      {lang === "th" ? "เก่าสุด - ล่าสุด" : "Oldest to Lastest"}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-[18px] mt-[38px]">
              {[...Array(pageSize)].map((item, i) => (
                <ScholarshipCard data={item} key={i} lang={lang} isImageBox />
              ))}
            </div>

            {[...Array(3)] <= 0 && (
              <p className="text-[23px] text-center mt-[40px]">
                {lang === "th"
                  ? "ไม่พบข้อมูลที่ค้นหา..."
                  : "Not found any news ..."}
              </p>
            )}

            {
              <Pagination
                length={7}
                selected={page}
                function={(i) => setPage(i)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
