import React from "react";

import chevRight from "../../assets/images/global/chev-right-small.svg";

export default function CustomButton(props) {
  return (
    <div
      className={
        `cursor-pointer bg-gold py-[11px] rounded-[10px] section gap-[16px] ` +
        (props.fit ? "!w-fit px-[56px]" : "w-full")
      }
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.hasIcon && <img src={chevRight} alt="" />}
      <p className="text-white text-[18px] xl:text-[22px]">{props.text}</p>
    </div>
  );
}
