// eslint-disable-next-line
export default new (class NavbarModel {
    // static API_URL = process.env.REACT_APP_API_URL;
  
    async get(data) {
      const model = {};
  
      model.id = data.id || 0;
      model.menu = data.attributes.Menu || "";
      model.link = data.attributes.Link.href || "";
      model.name_th = data.attributes.Link.label_TH || "";
      model.name_en = data.attributes.Link.label_EN || "";
      model.subMenu = data.attributes.subMenu || [];
      model.isOpen = false;
      model.order = data.attributes.order || "";
  
      return model;
    }
  })();
  