import React from "react";
// import timeFunction from "../../functions/getTime";

export default function JournalCard(props) {
  return (
    <div className="grid grid-cols-9 border-[1px] border-lightGray2 rounded-[12px] !bg-cover !bg-center">
      {props.appointment ? (
        <span
          className={
            `bg-lightGray col-span-4 rounded-l-[12px] !bg-cover !bg-center ` +
            (props.size !== "small" && "min-h-[200px]")
          }
          style={
            props.data &&
            props.data.image &&
            props.data.image.image.data !== null
              ? {
                  background: `url(${process.env.REACT_APP_API_URL}${props.data.image.image.data[0].attributes.url})`,
                }
              : { background: `#D9D9D9` }
          }
        />
      ) : (
        <span
          onClick={() => {
            if (props.page === "newsAndActivity/news")
              window.open(
                `${process.env.REACT_APP_WEB_URL}/${props.lang}/${props.page}/${props.data.slug}`,
                "_self"
              );
          }}
          className={
            `cursor-pointer bg-lightGray col-span-4 rounded-l-[12px] !bg-cover ` +
            (props.size !== "small" && "min-h-[200px]")
          }
          style={
            props.data && props.data.image[0]
              ? {
                  background: `url(${process.env.REACT_APP_API_URL}${props.data.image[0].attributes.url})`,
                }
              : { background: `#D9D9D9` }
          }
        />
      )}
      <div
        className={`justify-between col-span-5 flex flex-col gap-[35px] ${
          props.size === "small" ? "p-[15px]" : "p-[25px]"
        }`}
      >
        <p
          className={`capitalize cursor-pointer text-left font-medium line-clamp-2 ${
            props.size === "small"
              ? "text-[16px] xl:text-[18px] leading-[24px]"
              : "text-[18px] xl:text-[22px] leading-[29px]"
          }`}
          onClick={() => {
            if (props.page === "newsAndActivity/news")
              window.open(
                `${process.env.REACT_APP_WEB_URL}/${props.lang}/${props.page}/${props.data.slug}`,
                "_self"
              );
          }}
        >
          วารสารพยาบาล ปีที่ 58
        </p>

        <div className="pb-[80px]">
          {props.noDate !== true && (
            <p
              className={`text-left font-medium ${
                props.size === "small"
                  ? "text-[8px] xl:text-[10px]"
                  : "text-[14px] xl:text-[16px] my-[5px]"
              }`}
            >
              {/* {timeFunction.getTime(props.data.publishedAt, props.lang)} */}
              29 กันยายน 2565
            </p>
          )}

          {props.appointment !== true && props.data ? (
            <p
              className={`text-left font-normal sarabun line-clamp-2 ${
                props.size === "small"
                  ? "text-[8px] xl:text-[10px]"
                  : "text-[12px] xl:text-[14px] max-w-[70%]"
              }`}
            >
              {props.lang === "th"
                ? props.data?.description?.detail_TH
                : props.data?.description?.detail_EN}
            </p>
          ) : (
            <p
              className={`text-left font-normal sarabun line-clamp-2 text-[12px] xl:text-[14px] max-w-[95%] mt-[12px]`}
            >
              รายละเอียดจะแสดงบริเวณนี้
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
