import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./assets/css/page.css";

import Mainpage from "./pages/Mainpage.js";
import Navbar from "./components/Navbar";
import Hamburger from "./components/Hamburger";
import Footer from "./components/Footer";
import Service from "./pages/service/Service";
import MemberChecking from "./pages/service/member/MemberChecking";
import AcknowledgeWelfare from "./pages/service/AcknowledgeWelfare";
import Journals from "./pages/service/Journals";
import ContentDetail from "./pages/ContentDetail";
import NewsActivity from "./pages/news-activity/NewsActivity";
import NewsSearch from "./pages/news-activity/NewsSearch";
import OurPrides from "./pages/pride/OurPrides";
import PridesSearch from "./pages/pride/PridesSearch";
import Association from "./pages/pride/Association";
import AssociationDetail from "./pages/pride/AssociationDetail";
import AboutUs from "./pages/about-us/AboutUs";
import President from "./pages/about-us/President";
import Vision from "./pages/about-us/Vision";
import Objective from "./pages/about-us/Objective";
import History from "./pages/about-us/History";
import Organization from "./pages/about-us/Organization";
import Member from "./pages/about-us/Member";
import ContactUs from "./pages/contact-us/ContactUs";
import MemberRegistration from "./pages/service/member/MemberRegistration";
import MemberInfo from "./pages/service/member/MemberInfo";
import Login from "./pages/Login";
import ScholarShip from "./pages/service/ScholarShip";
import Training from "./pages/service/Training";

import ViewsService from "./services/views/views";

const PageLayout = ({ children }) => {
  useEffect(() => {
    const updateViewsData = async () => {
      try {
        await ViewsService.updateViews();
      } catch (error) {
        console.error("Error updating views:", error);
      }
    };

    updateViewsData();
  }, []);

  return (
    <div className="App">
      <Navbar data={[...Array(3)]} />
      <Hamburger data={[...Array(3)]} />
      {children}
      <Footer data={[...Array(3)]} />
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/:lang">
          <PageLayout>
            <Mainpage />
          </PageLayout>
        </Route>

        {/* Login */}
        <Route path="/:lang/login">
          <PageLayout>
            <Login />
          </PageLayout>
        </Route>

        {/* Service */}
        <Route exact path="/:lang/service">
          <div className="App">
            <PageLayout>
              <Service />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/service/member_check">
          <div className="App">
            <PageLayout>
              <MemberChecking />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/service/memberRegistration">
          <div className="App">
            <PageLayout>
              <MemberRegistration />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/service/memberInfo">
          <div className="App">
            <PageLayout>
              <MemberInfo />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/service/service_request">
          <div className="App">
            <PageLayout>
              <AcknowledgeWelfare />
            </PageLayout>
          </div>
        </Route>

        <Route path="/:lang/service/service_request/:slug">
          <div className="App">
            <PageLayout>
              <ContentDetail page="welfare" />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/service/apply_scholarship">
          <div className="App">
            <PageLayout>
              <ScholarShip />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/service/apply_trainning">
          <div className="App">
            <PageLayout>
              <Training />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/service/documents">
          <div className="App">
            <PageLayout>
              <Journals />
            </PageLayout>
          </div>
        </Route>

        <Route path="/:lang/service/documents/:slug">
          <div className="App">
            <PageLayout>
              <ContentDetail />
            </PageLayout>
          </div>
        </Route>

        {/* News */}
        <Route exact path="/:lang/newsAndActivity">
          <div className="App">
            <PageLayout>
              <NewsActivity />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/newsAndActivity/news">
          <div className="App">
            <PageLayout>
              <NewsSearch page="news" />
            </PageLayout>
          </div>
        </Route>

        <Route path="/:lang/newsAndActivity/news/:slug">
          <div className="App">
            <PageLayout>
              <ContentDetail page="news" />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/newsAndActivity/trainning_news">
          <div className="App">
            <PageLayout>
              <NewsSearch page="meeting" />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/newsAndActivity/scholarship_news">
          <div className="App">
            <PageLayout>
              <NewsSearch page="scholarship" />
            </PageLayout>
          </div>
        </Route>

        {/* Prides */}
        <Route exact path="/:lang/honor">
          <div className="App">
            <PageLayout>
              <OurPrides />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/honor/awards">
          <div className="App">
            <PageLayout>
              <PridesSearch page="award" />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/honor/social_activities">
          <div className="App">
            <PageLayout>
              <PridesSearch page="socialService" />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/honor/activities">
          <div className="App">
            <PageLayout>
              <Association />
            </PageLayout>
          </div>
        </Route>

        <Route path="/:lang/honor/activities/:slug">
          <div className="App">
            <PageLayout>
              <AssociationDetail />
            </PageLayout>
          </div>
        </Route>

        {/* About Us */}
        <Route exact path="/:lang/abouts">
          <div className="App">
            <PageLayout>
              <AboutUs />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/abouts/association">
          <div className="App">
            <PageLayout>
              <President />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/abouts/mission">
          <div className="App">
            <PageLayout>
              <Vision />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/abouts/objective">
          <div className="App">
            <PageLayout>
              <Objective />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/abouts/history">
          <div className="App">
            <PageLayout>
              <History />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/abouts/broading">
          <div className="App">
            <PageLayout>
              <Organization />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="/:lang/abouts/member_rule">
          <div className="App">
            <PageLayout>
              <Member />
            </PageLayout>
          </div>
        </Route>

        {/* Contact Us */}
        <Route exact path="/:lang/contact">
          <div className="App">
            <PageLayout>
              <ContactUs />
            </PageLayout>
          </div>
        </Route>

        <Route exact path="*">
          <Redirect to="/th" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
