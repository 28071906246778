import React, { useEffect, useState } from "react";
import AllButton from "../components/button/AllButton";
import SwiperContent from "../components/mainpage/SwiperContent";
import { HashLink as Link } from "react-router-hash-link";
import NewsCard from "../components/card/NewsCard";
import HorizontalCard from "../components/card/HorizontalCard";
import DateCard from "../components/card/DateCard";
import FAQ from "../components/mainpage/FAQ";

// import banner from "../assets/images/mainpage/temp-banner.png";
import trophy from "../assets/images/mainpage/trophy.png";
import CustomButton from "../components/button/CustomButton";
import userGroup from "../assets/images/mainpage/user-group.svg";
import NewsService from "../services/news-activity/news";
import ViewsService from "../services/views/views";
import SliderService from "../services/components/slider";
import { useHistory, useParams } from "react-router-dom";
import timeFunction from "../functions/getTime";

export default function Mainpage() {
  let [newsData, setNewsData] = useState([]);
  let [sliderData, setSliderData] = useState(null);
  let [viewsData, setViewsData] = useState(null);
  const [inputValue, setInputValue] = useState("");
  let { lang } = useParams();

  const history = useHistory();

  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getNewsData();
    getSliderData();
    getViews();
  }, []);

  const getSliderData = async () => {
    try {
      let res = await SliderService.getImage();
      setSliderData(res);
    } catch {
      console.error();
    }
  };

  const getNewsData = async () => {
    try {
      let [res] = await NewsService.getMainpageNews({
        page: 1,
        pageSize: 5,
      });
      setNewsData(res);
    } catch {
      console.error();
    }
  };

  const getViews = async () => {
    try {
      let res = await ViewsService.getViews({});
      setViewsData(res);
    } catch {
      console.error();
    }
  };

  // const searchMember = (input) => {
  //   const name = encodeURIComponent(input);
  //   history.push(`/th/service/member_check?name=${name}`);
  // };

  return (
    <div id="mainpage" className="pb-[36px] pt-[135px] lg:pt-[0px]">
      {/* Swiper Content */}
      {sliderData ? (
        <SwiperContent data={sliderData} />
      ) : (
        <div className="h-[480px] bg-lightGray" />
      )}
      <div className="section">
        <div className="page-container">
          <div>
            {/* ข่าวสารล่าสุด และ กิจกรรม */}
            <div
              id="mainpage-activity"
              className="mt-[80px] grid grid-cols-4 gap-[50px]"
            >
              <div className="col-span-3 items-start">
                <div className="flex justify-between">
                  <h1 className="text-[28px] xl:text-[32px] text-left">
                    {lang === "th"
                      ? "ข่าวสารและกิจกรรม ล่าสุด"
                      : "Lastest News and Activities"}
                  </h1>
                  <Link
                    className="flex justify-end"
                    to={`/${lang}/newsAndActivity/news`}
                  >
                    <AllButton lang={lang} />
                  </Link>
                </div>
                <div className="mt-[22px]">
                  {newsData && newsData[0] && (
                    <NewsCard
                      data={newsData[0]}
                      page="newsAndActivity/news"
                      lang={lang}
                    />
                  )}
                </div>

                <div
                  id="mainpage-activity-section"
                  className="grid grid-cols-2 gap-[18px] mt-[34px]"
                >
                  {newsData?.slice(1, 5).map((item, i) => (
                    <HorizontalCard
                      size="small"
                      data={item}
                      key={i}
                      page="newsAndActivity/news"
                      lang={lang}
                    />
                  ))}
                </div>
              </div>
              <div className="col-span-1 items-start">
                <div className="flex flex-col gap-[12px]">
                  <div>
                    <p className="text-[22px] xl:text-[26px] text-left">
                      ตรวจสอบสมาชิกภาพ
                    </p>
                    {/* <h1 className="text-[28px] xl:text-[32px] text-left">
                      หรือ <span className="text-gold">เข้าสู่ระบบ</span>
                    </h1> */}
                    <h1 className="text-[28px] xl:text-[32px] text-left text-darkGray">
                      เริ่มใช้งานได้ เร็วๆ นี้
                    </h1>
                    {/* <p className="text-[12px] xl:text-[14px] text-left">
                      กรุณากรอก ชื่อของท่าน ตามด้วย - และกรอกนามสกุล ตัวอย่าง
                      วันดี-ณ อยุธยา, พิศมัย-สมสกุล เป็นต้น
                    </p> */}
                  </div>
                  <div className="pointer-events-none py-[17px] px-[24px] flex flex-col gap-[10px] rounded-[12px] border-[1px] border-lightGray2 bg-gray-200 text-[gray-500] opacity-70">
                    <input
                      className="w-full placeholder-lightGray2 text-[16px] rounded-[8px] border-[1px] border-lightGray2 py-[14px] px-[18px]"
                      placeholder="กรุณากรอก ชื่อ-นามสกุล"
                      disabled
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <CustomButton
                      hasIcon
                      text={"ตรวจสอบ"}
                      onClick={() => {
                        // if (inputValue !== "") searchMember(inputValue);
                      }}
                    />
                  </div>
                  <div>
                    <p className="text-gold text-[20px] xl:text-[22px]">{`หรือ สมัครสมาชิก >>`}</p>
                  </div>
                </div>
                <div className="flex flex-col gap-[16px] mt-[20px]">
                  <h1 className="text-[28px] xl:text-[32px] text-left cursor-pointer">
                    {lang === "th" ? "สัมมนาและอบรม" : "Seminars and Training"}
                  </h1>
                  <div className="flex flex-col gap-[14px]">
                    {[...Array(2)].map((item, i) => (
                      <DateCard
                        key={i}
                        data={item}
                        hasBg={item && item.image[0] ? true : false}
                        lang={lang}
                      />
                    ))}
                  </div>
                  <div className="p-[30px] rounded-[12px] bg-lightGray3 min-h-[284px]">
                    <h1 className="text-[20px] text-left">
                      ติดตามได้เร็วๆ นี้...
                    </h1>
                  </div>
                  <div
                    className="flex justify-end"
                    onClick={() => {
                      history.push(`/${lang}/service/apply_trainning`);
                    }}
                  >
                    <AllButton lang={lang} />
                  </div>
                </div>
              </div>
            </div>

            {/* FAQ และ Health Update */}
            <div
              id="mainpage-faq"
              className="mt-[57px] grid grid-cols-2 gap-[28px]"
            >
              <div className="col-span-1">
                <h1 className="text-[28px] xl:text-[32px] text-left cursor-pointer">
                  ข่าวทุนการศึกษา/ทุนวิจัย
                </h1>
                <div className="mt-[20px] w-full p-[30px] rounded-[12px] bg-lightGray3 min-h-[284px]">
                  <h1 className="text-[20px] text-left">
                    ติดตามได้เร็วๆ นี้...
                  </h1>
                </div>
                <div className="mt-[20px]">
                  <FAQ data={[...Array(3)]} lang={lang} />
                </div>
                <div
                  className="flex justify-end mt-[16px]"
                  onClick={() => {
                    history.push(`/${lang}/service/apply_scholarship`);
                  }}
                >
                  <AllButton lang={lang} />
                </div>
              </div>
              <div className="col-span-1">
                <h1 className="text-[28px] xl:text-[32px] text-left cursor-pointer">
                  ผลงานและความภาคภูมิใจ
                </h1>
                <div
                  className="mt-[20px] flex items-end w-full p-[30px] rounded-[12px] bg-lightGray3 min-h-[284px] !bg-cover bg-center"
                  style={{ backgroundImage: `url(${trophy})` }}
                >
                  <h1 className="text-[20px] text-left text-white">
                    ติดตามได้เร็วๆ นี้...
                  </h1>
                </div>
                <div className="mt-[20px] flex gap-[17px]">
                  {[...Array(2)].map((item, i) => (
                    <NewsCard
                      key={i}
                      data={item}
                      lineClamp={2}
                      hideIcon={true}
                      lang={lang}
                    />
                  ))}
                </div>
                <div
                  className="flex justify-end mt-[16px]"
                  onClick={() => {
                    history.push(`/${lang}/honor/awards`);
                  }}
                >
                  <AllButton lang={lang} />
                </div>
              </div>
            </div>

            <div className="mt-[23px] flex flex-col lg:flex-row w-full items-start gap-[60px] py-[19px] pl-[24px] pr-[24px] xl:pr-[75px] border-[1px] rounded-[12px] border-gold">
              <div className="flex items-center gap-[14px]">
                <img className="w-[36px]" src={userGroup} alt="" />
                <p className="text-[26px] xl:text-[30px] text-black whitespace-normal md:whitespace-nowrap">
                  สถิติผู้เข้าชมเว็บไซต์
                </p>
              </div>
              <div className="flex lg:flex-row flex-col gap-[8px] pl-[10px] w-full">
                <div className="flex flex-row md:flex-col gap-[5px] items-start justify-start w-full">
                  <p className="text-brown text-[18px] text-left">
                    วันนี้{" "}
                    <span className="!text-[12px] xl:!text-[14px]">
                      ({timeFunction.getTodayDate()})
                    </span>
                  </p>
                  <p className="text-red text-[18px] text-left">
                    {viewsData?.today_views.toLocaleString() || ""}
                  </p>
                </div>
                <div className="flex flex-row md:flex-col gap-[5px] items-start justify-start w-full">
                  <p className="text-brown text-[18px] text-left">เดือนนี้</p>
                  <p className="text-red text-[18px] text-left">
                    {viewsData?.this_month_views.toLocaleString() || ""}
                  </p>
                </div>
                <div className="flex flex-row md:flex-col gap-[5px] items-start justify-start w-full">
                  <p className="text-brown text-[18px] text-left">ทั้งหมด</p>
                  <p className="text-red text-[18px] text-left">
                    {viewsData?.all_time_views.toLocaleString() || ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
