import React from "react";
import { Link, useParams } from "react-router-dom";
import PageBanner from "../../components/banner/PageBanner";
import AllButton from "../../components/button/AllButton";
import AwardCard from "../../components/card/AwardCard";
import HorizontalCard from "../../components/card/HorizontalCard";
import VerticleCard from "../../components/card/VerticleCard";

export default function OurPrides() {
  let { lang } = useParams();

  const bannerText = [{ name: "Works and Honor", href: "/honor" }];

  return (
    <div id="ourprides" className="pt-[135px] lg:pt-[0px] pb-[100px]">
      <PageBanner
        text_th={"ผลงาน & ความภาคภูมิใจ"}
        text_en={"Works and Honor"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          <div className="mt-[49px]">
            <h1 className="text-[28px] xl:text-[32px] text-left">
              {lang === "th" ? "บริการสมาชิก" : "Service"}
            </h1>

            <div className="mt-[27px] grid grid-cols-1 md:grid-cols-3 gap-[20px] h-fit">
              <div className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal">
                รางวัล/ผลงานพยาบาล
              </div>
              <div className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal">
                กิจกรรมบริหารสังคม
              </div>
              <div className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal">
                กิจกรรมของสมาคมฯ
              </div>
            </div>
          </div>

          <div className="mt-[49px]">
            <div className="flex items-center justify-between">
              <h1 className="text-[28px] xl:text-[32px] text-left font-normal">
                {lang === "th"
                  ? "รางวัล/ผลงานพยาบาล"
                  : "Award/Nursing Performance"}
              </h1>

              <Link
                className="flex justify-end"
                to={`/${lang}/newsAndActivity/activity`}
              >
                <AllButton lang={lang} />
              </Link>
            </div>

            <div className="mt-[32px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[20px]">
              {[...Array(4)].map((item, i) => (
                <AwardCard key={i} data={item} lang={lang} />
              ))}
            </div>
          </div>

          <div className="flex flex-col lg:grid lg:grid-cols-3 gap-[32px] mt-[49px]">
            <div className="col-span-1">
              <h1 className="text-[28px] xl:text-[32px] text-left font-normal">
                {lang === "th"
                  ? "กิจกรรมบริการสังคม"
                  : "Social Service Activities"}
              </h1>

              <div
                id="mainpage-activity-section"
                className="grid grid-cols-1 gap-[18px] mt-[34px]"
              >
                {[...Array(2)].map((item, i) => (
                  <HorizontalCard
                    size="small"
                    data={item}
                    key={i}
                    page="newsAndActivity/news"
                    lang={lang}
                  />
                ))}
              </div>
              <Link
                className="flex justify-end mt-[20px]"
                to={`/${lang}/newsAndActivity/activity`}
              >
                <AllButton lang={lang} />
              </Link>
            </div>
            <div className="col-span-2">
              <h1 className="text-[28px] xl:text-[32px] text-left font-normal">
                {lang === "th"
                  ? "กิจกรรมของสมาคมฯ"
                  : "Activities of the Association"}
              </h1>

              <div
                id="mainpage-activity-section"
                className="grid grid-cols-2 gap-[18px] mt-[34px]"
              >
                {[...Array(2)].map((item, i) => (
                  <VerticleCard
                    data={item}
                    key={i}
                    // page="newsAndActivity/activity"
                    lang={lang}
                  />
                ))}
              </div>

              <Link
                className="flex justify-end mt-[20px]"
                to={`/${lang}/newsAndActivity/activity`}
              >
                <AllButton lang={lang} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
