import http from "./../http-common";
import qs from "qs";
import NewsModel from "../../models/news-activity/news";

// eslint-disable-next-line
export default new (class NewsService {
  // Get all
  async getNews({
    keyword,
    startDate,
    endDate,
    sort = "desc",
    page = 1,
    pageSize = 5,
  }) {
    const query = qs.stringify(
      {
        sort: [`publishedAt:${sort}`],
        filters: {
          $or: [
            {
              title: {
                detail_TH: {
                  $contains: keyword,
                },
              },
            },
            {
              title: {
                detail_EN: {
                  $contains: keyword,
                },
              },
            },
          ],
          $and: [
            {
              publishedAt: {
                $lt: new Date(endDate).toISOString(),
                $gt: new Date(startDate).toISOString(),
              },
            },
          ],
        },
        populate: "deep",
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    return await http
      .get("/news?" + query)
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((news) => NewsModel.get(news))
        );

        const meta = response.data.meta;

        return [data, meta];
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getMainpageNews({ sort = "desc", page = 1, pageSize = 3 }) {
    const query = qs.stringify(
      {
        sort: [`publishedAt:${sort}`],
        populate: "deep",
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    return await http
      .get("/news?" + query)
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((news) => NewsModel.get(news))
        );

        return [data];
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Get by name
  async getNewsBySlug(slug) {
    return await http
      .get(`/news?filters[Slug][$eq]=${slug}`)
      .then(async (response) => {
        const id = response.data.data[0].id;

        const news = await http
          .get(`/news/${id}?populate=deep`)
          .then(async (response) => {
            const data = await NewsModel.get(response.data.data);
            return data;
          });

        return news;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getNewsByTag({ sort = "desc", tag = "" }) {
    const query = qs.stringify(
      {
        filters: {
          $and: [
            {
              tag: {
                tag: {
                  $eq: tag,
                },
              },
            },
          ],
        },
        sort: [`publishedAt:${sort}`],
        populate: "deep",
      },
      {
        encodeValuesOnly: true,
      }
    );
    return await http
      .get("/news?" + query)
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((news) => NewsModel.get(news))
        );

        return [data];
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
