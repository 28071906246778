import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faLine } from "@fortawesome/free-brands-svg-icons";
import TextBanner from "../components/banner/TextBanner";
import NewsService from "../services/news-activity/news";
import WelfareService from "../services/service/welfare";
import timeFunction from "../functions/getTime";

export default function ContentDetail(props) {
  let { lang, slug } = useParams();
  let [contentData, setContentData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getContentData();
    // eslint-disable-next-line
  }, []);

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  const shareOnFacebook = () => {
    const url = window.location.href; // get the current URL
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`; // create the Facebook share URL with the current URL as a parameter
    window.open(facebookUrl, "_blank", "width=600,height=400"); // open the Facebook share dialog in a new tab
  };

  const shareOnLine = () => {
    const url = window.location.href; // get the current URL
    const lineUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
      url
    )}`; // create the Line share URL with the current URL as a parameter
    window.open(lineUrl, "_blank"); // open the Line share dialog in a new tab
  };

  const getContentData = async () => {
    try {
      if (props.page === "news") {
        let res = await NewsService.getNewsBySlug(`${slug}`);

        if (res) {
          setContentData(res);
        } else {
          window.open(
            `${process.env.REACT_APP_WEB_URL}/${lang}/newsAndActivity/news`,
            "_self"
          );
        }
      } else if (props.page === "welfare") {
        let res = await WelfareService.getWelfareBySlug(`${slug}`);

        if (res) {
          setContentData(res);
        } else {
          window.open(
            `${process.env.REACT_APP_WEB_URL}/${lang}/service/service_request`,
            "_self"
          );
        }
      }
      // else if (props.page === "activities") {
      //   let res = await ActivitiesService.getActivitiesBySlug(`${slug}`);

      //   if (res) {
      //     setContentData(res);
      //   } else {
      //     window.open(
      //       `${process.env.REACT_APP_WEB_URL}/${lang}/newsAndActivity/activity`,
      //       "_self"
      //     );
      //   }
      // }
      // else if (props.page === "article") {
      //   let res = await ArticlesService.getArticlesBySlug(`${slug}`);

      //   if (res) {
      //     setContentData(res);
      //   } else {
      //     window.open(
      //       `${process.env.REACT_APP_WEB_URL}/${lang}/article`,
      //       "_self"
      //     );
      //   }
      // }
    } catch {
      console.error();
    }
  };

  const isLinkPage = () => {
    if (
      props.page === "newsAndActivity/news" ||
      props.page === "service/service_request"
    ) {
      return true;
    }
    return false;
  };

  const bannerText = () => {
    if (contentData.title && props.page === "news")
      return [
        { name: "News and Activity", href: "/newsAndActivity" },
        { name: "News", href: "/newsAndActivity/news" },
        {
          name:
            lang === "th"
              ? contentData?.title?.detail_TH
              : contentData?.title?.detail_EN,
        },
      ];
    else if (contentData.title && props.page === "welfare")
      return [
        { name: "Service", href: "/service" },
        { name: "Service Request", href: "/service/service_request" },
        {
          name:
            lang === "th"
              ? contentData?.title?.detail_TH
              : contentData?.title?.detail_EN,
        },
      ];
    return [];
  };

  return (
    contentData.content && (
      <div className="content-detail pt-[135px] lg:pt-[0px]">
        <TextBanner
          text_th={contentData?.title?.detail_TH}
          text_en={contentData?.title?.detail_EN}
          // banner={newsBanner.banner}
          bannerText={bannerText()}
          lang={lang}
        />
        {contentData.content && (
          <div className="section">
            <div className="page-container">
              <div className="mt-[54px] mb-[46px]">
                {contentData.title && isLinkPage && (
                  <h1 className="text-[28px] xl:text-[32px] text-left capitalize mt-[25px]">
                    {lang === "th"
                      ? contentData.title.detail_TH
                      : contentData.title.detail_EN}
                  </h1>
                )}

                <div className="w-[120px] border-[1px] border-black my-[22px]" />

                <div className="flex justify-start items-start gap-[18px]">
                  <div className="flex items-center gap-[12px]">
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-[24px] cursor-pointer"
                    />
                    <p className="text-[18px] mt-[3px]">
                      {contentData &&
                        timeFunction.getTime(
                          props.page === "activities"
                            ? contentData?.date?.startDate
                            : contentData?.publishedAt,
                          lang
                        )}
                    </p>
                  </div>
                  <div className="flex items-center gap-[12px]">
                    <p className="text-[18px] mt-[3px]">
                      {contentData?.tag?.map((item, i) => (
                        <span key={i}>
                          <>
                            <span
                              className="cursor-pointer text-blue hover:underline"
                              // onClick={() => {
                              //   window.open(
                              //     `${process.env.REACT_APP_WEB_URL}/${lang}/search/${item.tag}`,
                              //     "_self"
                              //   );
                              // }}
                            >
                              #{item.tag}
                            </span>
                            {i + 1 < contentData?.tag?.length && ", "}
                          </>
                        </span>
                      ))}
                    </p>
                  </div>
                </div>

                {contentData.content && isLinkPage && (
                  <div
                    className="content-html text-left my-[30px]"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "th"
                          ? contentData.content[0]?.body_TH
                          : contentData.content[0]?.body_EN,
                    }}
                  ></div>
                )}

                {props.page === "news" && (
                  <div className="flex justify-end items-center gap-[18px] whitespace-nowrap">
                    <div className="w-full border-[1px] border-black my-[22px]" />
                    <div className="flex gap-[0px] items-center">
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[24px]"
                      />
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[24px]"
                      />
                    </div>
                    <p
                      className="text-[18px] cursor-pointer hover:underline"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_WEB_URL}/${lang}/newsAndActivity/news/`,
                          "_self"
                        )
                      }
                    >
                      {lang === "th" ? (
                        <span>
                          {props.page === "news"
                            ? "ข่าวสารทั้งหมด"
                            : props.page === "activites"
                            ? "กิจกรรมทั้งหมด"
                            : "บทความทั้งหมด"}
                        </span>
                      ) : (
                        <span>
                          {props.page === "news"
                            ? "All News"
                            : props.page === "activites"
                            ? "All Activites"
                            : "All Articles"}
                        </span>
                      )}
                    </p>
                  </div>
                )}

                <div className="flex justify-end mt-[15px]">
                  <div className="flex flex-col items-start justify-end w-fit">
                    <p className="mb-[10px] text-[18px]">Share : </p>
                    <div className="flex items-center justify-end gap-[25px]">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="transition hover:scale-110 delay-50 text-[40px] cursor-pointer text-[#3b5998]"
                        onClick={() => shareOnFacebook()}
                      />
                      <FontAwesomeIcon
                        icon={faLine}
                        className="transition hover:scale-110 delay-50 text-[40px] cursor-pointer text-[#06C755]"
                        onClick={() => shareOnLine()}
                      />
                      <div
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href);
                        }}
                        className="transition hover:scale-110 delay-50 cursor-pointer w-[45px] h-[45px] bg-lightGray hover:bg-lightGray rounded-full section text-[10px] p-[3px]"
                      >
                        Copy Link
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
}
