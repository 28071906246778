// eslint-disable-next-line
export default new (class NewsModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.title = data.attributes.title || [];
    model.description = data.attributes.description || [];
    model.tag = data.attributes.tag || [];
    model.slug = data.attributes.Slug || "";

    if (
      data.attributes.image.length > 0 &&
      data.attributes.image[0].image.data
    ) {
      model.image = data.attributes.image[0].image.data;
    } else {
      model.image = "";
    }

    if (data.attributes.content.length > 0) {
      model.content = data.attributes.content;
    } else {
      model.content = [];
    }

    model.createdAt = data.attributes.createdAt || "";
    model.publishedAt = data.attributes.publishedAt || "";

    return model;
  }
})();
