import React from "react";
import chevBrown from "../../assets/images/global/chev-brown.svg";

export default function PageBanner(props) {
  return (
    <div
      className="section bg-lightBg !bg-cover !bg-center"
      style={{
        background:
          props.banner &&
          (props.isVideo
            ? `url(${props.banner}) center center / cover no-repeat`
            : `url(${process.env.REACT_APP_API_URL}${props.banner.image.data[0].attributes.url}), #D9D9D9`),
      }}
    >
      <div className="page-container h-[300px] items-start flex flex-col justify-end mb-[50px]">
        {props.bannerText && (
          <p className="text-[16px] xl:text-[18px] font-medium text-left text-brown cursor-pointer flex flex-wrap gap-[12px]">
            <a href={`/${props.lang}`}>
              {props.lang === "th" ? "หน้าหลัก" : "Mainpage"}
            </a>
            {props.bannerText.map((item, i) => (
              <span
                key={i}
                className="flex items-center gap-[13px]"
                onClick={() => {
                  if (
                    item.href &&
                    window.location.pathname !== `/${props.lang}${item.href}`
                  ) {
                    window.open(
                      `${process.env.REACT_APP_WEB_URL}/${props.lang}${item.href}`,
                      "_self"
                    );
                  }
                }}
              >
                {" "}
                <img src={chevBrown} alt="" />{" "}
                <span className="capitalize">{item.name}</span>
              </span>
            ))}
          </p>
        )}
        <h1 className="text-[28px] xl:text-[36px] font-medium text-left">
          {props.lang === "th" ? props.text_th : props.text_en}
        </h1>
      </div>
    </div>
  );
}
