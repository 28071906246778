import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageBanner from "../../components/banner/PageBanner";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

export default function ContactUs() {
  // eslint-disable-next-line
  let [contactUsData, setContactUsData] = useState([]);
  // eslint-disable-next-line
  let [contactUsBanner, setContactUsBanner] = useState([]);
  let { lang } = useParams();

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // getAboutUsData();
    // getContactUsBanner();
    // eslint-disable-next-line
  }, []);

  //   const getAboutUsData = async () => {
  //     try {
  //       let res = await AboutUsService.getContact();
  //       setContactUsData(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  //   const getContactUsBanner = async () => {
  //     try {
  //       let res = await BannerService.getBanner(5);
  //       setContactUsBanner(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  const containerStyle = {
    width: "100%",
    height: "457px",
  };

  const center = {
    lat: -3.745,
    lng: -38.523,
  };

  // eslint-disable-next-line
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBKO8hQ6dQXSYuQtnJNqCK-CKTDJuYGDH0",
  });

  // eslint-disable-next-line
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
    // eslint-disable-next-line
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function mapComponent(title, location) {
    const locationDouble =
      lang === "th"
        ? location[0].detail_TH.split(", ")
        : location[0].detail_EN.split(", ");
    const locationCenter = {
      lat: parseFloat(locationDouble[0]),
      lng: parseFloat(locationDouble[1]),
    };

    return (
      <div>
        <h1 className="text-left text-[28px] xl:text-[32px]">{title}</h1>
        {location ? (
          <GoogleMap
            mapTypeId={"hybrid"}
            mapContainerStyle={containerStyle}
            center={locationCenter}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <MarkerF
              position={{
                lat: parseFloat(locationDouble[0]),
                lng: parseFloat(locationDouble[1]),
              }}
            />
          </GoogleMap>
        ) : (
          <div className="border-[1px] border-lightGray2 rounded-[12px] h-[457px] w-full mt-[15px]" />
        )}
      </div>
    );
  }

  function bulletComponent(title, content) {
    return (
      <div>
        <h1 className="text-left text-[28px] xl:text-[32px] mt-[35px]">
          {title}
        </h1>
        <BulletText
          text={lang === "th" ? content[0].detail_TH : content[0].detail_EN}
        />
      </div>
    );
  }

  function socialComponent(data) {
    return (
      <div className="flex justify-around mt-[25px]">
        {data &&
          data.map((item, i) => (
            <div
              key={i}
              className="w-full flex flex-col gap-[12px] justify-start items-center"
            >
              <div
                className="bg-lightGray border-lightGray rounded-full h-[96px] w-[96px] !bg-center !bg-cover"
                style={{
                  background: `url(${process.env.REACT_APP_API_URL}${item.image.data[0].attributes.url}), #D9D9D9`,
                }}
              />
              <h1 className="text-[18px] xl:text-[20px] text-center">
                {item.caption}
              </h1>
            </div>
          ))}
      </div>
    );
  }

  function subBulletComponent(title, content) {
    return (
      <div>
        <h1 className="text-left text-[28px] xl:text-[32px]">{title}</h1>

        {content &&
          content.map((data, i) => (
            <div key={i}>
              <p className="text-[18px] xl:text-[22px] text-left font-semibold mt-[15px] mb-[17px]">
                {lang === "th" ? data.subTitle_TH : data.subTitle_EN}
              </p>
              {data.content &&
                data.content.map((text, i) => (
                  <BulletText
                    key={i}
                    text={lang === "th" ? text.detail_TH : text.detail_EN}
                  />
                ))}
            </div>
          ))}
      </div>
    );
  }

  function BulletText(props) {
    return (
      <div className="flex gap-[14px] my-[10px]">
        <div>
          <div className="h-[26px] w-[26px] rounded-full bg-lightGray" />
        </div>
        <div>
          <p className="text-[16px] xl:text-[18px] text-left whitespace-pre-line">
            {props.text}
          </p>
          {props.text2 && (
            <p className="text-[16px] xl:text-[18px] text-left whitespace-pre-line">
              {props.text2}
            </p>
          )}
        </div>
      </div>
    );
  }

  const bannerText = [{ name: "Contact us", href: "/contact" }];

  return (
    <div id="contact" className="pt-[135px] lg:pt-[0px] pb-[100px]">
      <PageBanner
        text_th={"ติดต่อเรา"}
        text_en={"Contact"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          <div className="mt-[49px] grid grid-cols-1 xl:grid-cols-2 gap-[40px]">
            <div className="left-contact">
              {contactUsData &&
                contactUsData
                  .filter((contact) => contact.leftSection === true)
                  .map((data, i) => (
                    <div key={i}>
                      {data.type === "map" &&
                        mapComponent(
                          lang === "th" ? data.title_th : data.title_en,
                          data.content
                        )}
                      {data.type === "email" &&
                        bulletComponent(
                          lang === "th" ? data.title_th : data.title_en,
                          data.content
                        )}
                      {data.type === "social" && socialComponent(data.content)}
                    </div>
                  ))}
            </div>

            <div className="right-contact">
              {contactUsData &&
                contactUsData
                  .filter((contact) => contact.leftSection === false)
                  .map((data, i) => (
                    <div key={i}>
                      {data.type === "Locations" &&
                        subBulletComponent(
                          lang === "th" ? data.title_th : data.title_en,
                          data.content
                        )}
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
