import React from "react";

export default function StaffCard(props) {
  return (
    <div className="w-full rounded-[12px] border-[1px] border-gold">
      <div
        className="h-[250px] bg-lightGray rounded-t-[12px] !bg-cover !bg-center"
        style={{
          background: `url(${process.env.REACT_APP_API_URL}${props.data?.profile_pic?.data.attributes.url}), #D9D9D9`,
        }}
      />
      <div className="p-[20px]">
        <div>
          <p className="text-[18px] xl:text-[22px] text-left">
            {/* {props.lang === "th" ? props.data.name_TH : props.data.name_EN} */}
            ชื่อจริง นามสกุล
          </p>
          <p className="text-[18px] xl:text-[22px] text-left">
            {/* {props.lang === "en" ? props.data.name_TH : props.data.name_EN} */}
            Name Family-Name
          </p>

          <p className="font-normal text-[20px] xl:text-[22px] mt-[10px] text-left line-clamp-2 text-darkGray">
            {/* {props.lang === "th" ? props.data.role_TH : props.data.role_EN} */}
            นายกสมาคมพยาบาลฯ
          </p>

          <p className="font-normal text-[16px] xl:text-[18px] mt-[5px] text-left line-clamp-2 text-darkGray">
            {/* {props.lang === "th" ? props.data.role_TH : props.data.role_EN} */}
            สำนักงานสาขาภาคตะวันออก
          </p>
          {props.is_staff && (
            <div>
              <p className="font-normal text-[16px] xl:text-[18px] mt-[10px] text-left line-clamp-2 text-darkGray">
                {/* {props.data.email} */}
              </p>
              <p className="font-normal text-[16px] xl:text-[18px] text-left line-clamp-2 text-darkGray">
                {/* {props.data.contact} */}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
