import React from "react";

export default function AwardCard(props) {
  return (
    <div className="flex flex-col gap-[15px]">
      <div className="h-[260px] bg-lightGray rounded-[12px]" />
      <div className="flex flex-col gap-[5px] px-[15px]">
        <p className="text-[16px] xl:text-[18px] text-left text-brown line-clamp-3">
          ขอแสดงความยินดี ผศ.ดร.เสาวมาศ คุณล้าน เถื่อนนาดี
          ได้รับรางวัลศิษย์เก่าเกียรติยศ ประจำปี 2565 “ด้านวิชาชีพ”
          มหาวิทยาลัยขอนแก้น
        </p>
        <p className="text-[10px] xl:text-[12px] text-left text-brown">
          29 กันยายน 2565
        </p>
      </div>
    </div>
  );
}
