import React from "react";
import chevronRed from "../assets/images/global/chev_red.svg";
import chevronGray from "../assets/images/global/chev-gray.svg";

export default function Pagination(props) {
  if (props.length > 1)
    return (
      <div className="pagination mt-[100px] section gap-[12px]">
        <div
          onClick={() => {
            if (props.selected - 1 > 0) props.function(props.selected - 1);
          }}
        >
          <img className="w-[18px] cursor-pointer" src={chevronGray} alt="" />
        </div>
        {[...Array(props.length)].map((item, i) => (
          <span key={i} className="text-[24px] font-medium cursor-pointer">
            <span
              className={
                i + 1 === props.selected ? "text-gold underline" : "text-red"
              }
              onClick={() => {
                props.function(i + 1);
              }}
            >
              {i + 1}
            </span>
            {i + 1 < props.length && (
              <span
                className={
                  i + 1 === props.selected ? "text-gold underline" : "text-red"
                }
                onClick={() => {
                  props.function(i + 1);
                }}
              >
                ,{" "}
              </span>
            )}
          </span>
        ))}
        <div
          onClick={() => {
            if (props.selected + 1 <= props.length)
              props.function(props.selected + 1);
          }}
        >
          <img className="w-[18px] cursor-pointer" src={chevronRed} alt="" />
        </div>
      </div>
    );
}
