import React from "react";

export default function ScholarshipCard(props) {
  function isLinkPage() {
    if (
      props.page === "newsAndActivity/news" ||
      props.page === "service/service_request"
    ) {
      window.open(
        `${process.env.REACT_APP_WEB_URL}/${props.lang}/${props.page}/${props.data.slug}`,
        "_self"
      );
    }
  }

  return (
    <div className="flex rounded-[12px] border-[1px] border-lightGray2 w-full min-h-[200px]">
      {props.isImageBox && (
        <div
          onClick={() => isLinkPage()}
          className="rounded-l-[12px] w-[30%] h-full !bg-center !bg-cover"
          style={
            props.data &&
            props.data?.image &&
            props.data?.image?.image?.data !== null
              ? {
                  background: `url(${process.env.REACT_APP_API_URL}${props.data?.image[0].attributes.url})`,
                }
              : { background: `#D9D9D9` }
          }
        />
      )}
      <div className="flex flex-col gap-[10px] py-[10px] px-[10px] md:py-[27px] md:px-[32px] w-full justify-between">
        <div>
          <h1
            className="w-full lg:w-[70%] line-clamp-2 cursor-pointer text-[22px] xl:text-[24px] text-left"
            onClick={() => isLinkPage()}
          >
            {props.lang === "th"
              ? props.data?.title?.detail_TH
              : props.data?.title?.detail_EN}
          </h1>

          <p className="mt-[10px] line-clamp-2 text-[12px] xl:text-[14px] sarabun text-left w-full lg:w-[70%]">
            {props.lang === "th"
              ? props.data?.description?.detail_TH
              : props.data?.description?.detail_EN}
          </p>
        </div>
        {/* <p className="text-[14px] xl:text-[16px] text-left">ทุนวิจัย</p> */}
        <p
          className="text-orange text-[12px] xl:text-[14px] cursor-pointer font-bold text-right"
          onClick={() => isLinkPage()}
        >
          อ่านเพิ่มเติม
        </p>
      </div>
    </div>
  );
}
