import React from "react";
import { useParams } from "react-router-dom";
import globe from "../assets/images/navbar/red-globe.svg";
import logo from "../assets/images/global/logo.png";
import CustomButton from "../components/button/CustomButton";

export default function Login() {
  let { lang } = useParams();

  function switchLang() {
    const currentPath = window.location.pathname;
    const currentLanguage = currentPath.split("/")[1]; // get the current language code from the path
    let newLanguage;
    if (currentLanguage === "en") {
      newLanguage = "th";
    } else if (currentLanguage === "th") {
      newLanguage = "en";
    }
    let newPath;
    if (currentPath.endsWith(`/${currentLanguage}`)) {
      // if the current path ends with "/en" or "/th", replace it with the new language code
      newPath = currentPath.replace(`/${currentLanguage}`, `/${newLanguage}`);
    } else {
      // otherwise, replace the language code in the middle of the path
      newPath = currentPath.replace(`/${currentLanguage}/`, `/${newLanguage}/`);
    }
    window.location.href = window.location.origin + newPath;
  }

  return (
    <div id="login" className="section">
      <div className="page-container">
        <div className="my-[41px]">
          <div
            className="section gap-[9px] cursor-pointer !justify-end"
            onClick={() => {
              switchLang();
            }}
          >
            <img className="w-[36px]" src={globe} alt="" />
            <p className="text-[18px] xl:text-[22px] text-red">
              {lang === "th" ? "TH" : "EN"}
            </p>
          </div>

          <div className="section mt-[40px] flex-col">
            <img className="w-[120px] lg:w-[180px]" src={logo} alt="" />
            <p className="text-[26px] md:text-[34px] lg:text-[40px] mt-[25px] leading-[49px] font-normal">
              สมาคมพยาบาลแห่งประเทศไทยฯ <br className="hidden lg:block" />
              สำนักงานสาขาภาคตะวันออก
            </p>

            <p className="text-[24px] md:text-[32px] lg:text-[34px] mt-[25px] mt-[35px]">
              เข้าสู่ระบบสมาชิกสมาคมฯ
            </p>

            <div className="mt-[30px] lg:w-fit w-full">
              <div className="section flex-col gap-[25px] w-full">
                <div className="flex flex-col items-start gap-[5px] lg:w-fit w-full">
                  <h1 className="text-[22px] lg:text-[24px]">เลขบัตรประชาชน</h1>
                  <input className="lg:w-[400px] w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
                </div>

                <div className="flex flex-col items-start gap-[5px] lg:w-fit w-full">
                  <h1 className="text-[22px] lg:text-[24px]">รหัสผ่าน</h1>
                  <input className="lg:w-[400px] w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
                </div>
              </div>
            </div>

            <div className="mt-[24px] lg:w-[400px] w-full">
              <CustomButton text={"เข้าสู่ระบบ"} />
            </div>

            <div className="divide-x flex gap-[20px] items-center mt-[24px]">
              <p className="text-gold text-[20px] lg:text-[22px]">
                ลืมรหัสผ่าน
              </p>
              <p className="pl-[20px] text-gold text-[20px] lg:text-[22px]">
                สมัครสมาชิก
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
