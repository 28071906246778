// eslint-disable-next-line
export default new (class SliderModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.order = data.attributes.order || 0;
    model.title = data.attributes.title || "";
    model.image =
      data.attributes.image.image.data[0].attributes.formats.large.url || "";

    return model;
  }
})();
