import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PageBanner from "../../components/banner/PageBanner";
import AllButton from "../../components/button/AllButton";
import DateCard from "../../components/card/DateCard";
import HorizontalCard from "../../components/card/HorizontalCard";
import NewsCard from "../../components/card/NewsCard";
import ScholarshipCard from "../../components/card/ScholarshipCard";
import NewsService from "../../services/news-activity/news";
import BannerService from "../../services/components/banner";

export default function NewsActivity() {
  let [newsData, setNewsData] = useState([]);
  let [newsBanner, setNewsBanner] = useState([]);
  let { lang } = useParams();

  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getNewsData();
    getNewsBanner();
  }, []);

  const getNewsData = async () => {
    try {
      let [res] = await NewsService.getMainpageNews({
        page: 1,
        pageSize: 5,
      });
      setNewsData(res);
    } catch {
      console.error();
    }
  };

  const getNewsBanner = async () => {
    try {
      let res = await BannerService.getBanner(2);
      setNewsBanner(res);
    } catch {
      console.error();
    }
  };

  const bannerText = [{ name: "news & activity", href: "/newsAndActivity" }];

  return (
    <div id="news" className="pt-[135px] lg:pt-[0px]">
      <PageBanner
        text_th={"ข่าวสาร & กิจกรรม"}
        text_en={"News & Activity"}
        banner={newsBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* ข่าวสารล่าสุด และ กิจกรรม */}
          <div
            id="mainpage-activity"
            className="mt-[80px] grid grid-cols-4 gap-[50px] mb-[50px]"
          >
            <div className="col-span-3 items-start">
              <div className="flex justify-between">
                <h1 className="text-[28px] xl:text-[32px] text-left">
                  {lang === "th" ? "ข่าวสารทั่วไป" : "News"}
                </h1>
                <Link
                  className="flex justify-end"
                  to={`/${lang}/newsAndActivity/news`}
                >
                  <AllButton lang={lang} />
                </Link>
              </div>
              <div className="mt-[22px]">
                {newsData && newsData[0] && (
                  <NewsCard
                    data={newsData[0]}
                    page="newsAndActivity/news"
                    lang={lang}
                  />
                )}
              </div>

              <div
                id="mainpage-activity-section"
                className="grid grid-cols-2 gap-[18px] mt-[34px]"
              >
                {newsData?.slice(1, 5).map((item, i) => (
                  <HorizontalCard
                    size="small"
                    data={item}
                    key={i}
                    page="newsAndActivity/news"
                    lang={lang}
                  />
                ))}
              </div>
            </div>
            <div className="col-span-1 items-start">
              <div className="flex flex-col gap-[16px]">
                <h1 className="text-[28px] xl:text-[32px] text-left">
                  {lang === "th" ? "สัมมนาและอบรม" : "Seminars and Training"}
                </h1>
                <div className="p-[30px] rounded-[12px] bg-lightGray3 min-h-[284px]">
                  <h1 className="text-[20px] text-left">
                    ติดตามได้เร็วๆ นี้...
                  </h1>
                </div>
                {/* <div className="flex flex-col gap-[14px]">
                  {[...Array(3)].map((item, i) => (
                    <DateCard
                      key={i}
                      data={item}
                      hasBg={item && item.image[0] ? true : false}
                      lang={lang}
                    />
                  ))}
                </div>
                <Link
                  className="flex justify-end"
                  to={`/${lang}/newsAndActivity/activity`}
                >
                  <AllButton lang={lang} />
                </Link> */}
              </div>
            </div>
          </div>

          <div className="mb-[80px]">
            <h1 className="text-[28px] xl:text-[32px] text-left">
              {lang === "th"
                ? "ข่าวทุนการศึกษา / ทุนวิจัย"
                : "Scholarship News / Research Grants"}
            </h1>
            <div className="mt-[20px] w-full p-[30px] rounded-[12px] bg-lightGray3 min-h-[284px]">
              <h1 className="text-[20px] text-left">ติดตามได้เร็วๆ นี้...</h1>
            </div>
            {/* <div className="flex flex-col gap-[28px] mt-[27px]">
              {[...Array(3)].map((item, i) => (
                <ScholarshipCard key={i} data={item} lang={lang} />
              ))}
            </div>
            <Link
              className="flex justify-end mt-[20px]"
              to={`/${lang}/newsAndActivity/news`}
            >
              <AllButton lang={lang} />
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
