import React, { useEffect, useState } from "react";
// import ServiceService from "../../services/service";
// import BannerService from "../../services/banner";
import { useParams } from "react-router-dom";
import TextBanner from "../../../components/banner/TextBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faClone } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../components/button/CustomButton";
import Datepicker from "react-tailwindcss-datepicker";

export default function MemberRegistration() {
  //   let [serviceData, setServiceData] = useState([]);
  //   let [serviceBanner, setServiceBanner] = useState([]);

  let { lang } = useParams();
  let [clickRegis, setClickRegis] = useState(false);
  const [date, setDate] = useState(null);
  const [sort, setSort] = useState("desc");

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // getServiceData();
    // getServiceBanner();
  }, [clickRegis]);

  //   const getServiceData = async () => {
  //     try {
  //       let res = await ServiceService.getService();
  //       setServiceData(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  //   const getServiceBanner = async () => {
  //     try {
  //       let res = await BannerService.getBanner(1);
  //       setServiceBanner(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  const CopyAccNum = (num) => {
    navigator.clipboard
      .writeText(num)
      .then()
      .catch((err) => alert("Failed to copy: " + err));
  };

  const handleFileUpload = (event) => {
    // eslint-disable-next-line
    const file = event.target.files[0];
  };

  const bannerText = [
    { name: "service", href: "/service" },
    { name: "Member Registration", href: "/service/memberRegistration" },
  ];

  function Regulation() {
    return (
      <div>
        <h1 className="text-[26px] md:text-[30px] xl:text-[36px] text-center">
          ระเบียบและข้อกำหนดในการสมัครเป็นสมาชิก
        </h1>

        <div className="bg-lightBg w-full h-[334px] mt-[34px]" />

        <div className="mt-[35px] mb-[42px] flex flex-col gap-[26px]">
          <h1 className="text-[28px] xl:text-[32px] text-left font-normal">
            การเตรียมเอกสารสำหรับการสมัครสมาชิก
          </h1>

          <div className="flex lg:flex-row flex-col gap-[46px]">
            <div className="flex items-center gap-[20px] justify-center md:flex-row flex-col">
              <div className="section bg-lightBg text-darkGray text-[18px] lg:text-[20px] px-[40px] min-h-[330px]">
                ตัวอย่างภาพถ่ายเพื่อใช้
                <br />
                สมัครสมาชิก
              </div>
              <div className="section bg-lightBg text-darkGray text-[18px] lg:text-[20px] px-[40px] min-h-[330px]">
                ตัวอย่างภาพถ่ายเพื่อใช้
                <br />
                สมัครสมาชิก
              </div>
            </div>
            <div className="mt-[20px]">
              <h1 className="text-[20px] lg:text-[24px] text-left font-normal">
                รายการเอกสาร
              </h1>
              <div className="mt-[17px] px-[20px] flex flex-col gap-[20px]">
                {[...Array(3)].map((item, i) => (
                  <div className="flex items-center gap-[16px]" key={i}>
                    <FontAwesomeIcon
                      icon={faFile}
                      className="text-[24px] cursor-pointer"
                    />
                    <h1 className="text-[16px] lg:text-[18px] text-left font-normal">
                      รูปภาพ
                    </h1>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[45px]">
          <h1 className="text-[26px] md:text-[30px] lg:text-[36px] text-left">
            ข้อมูลการชำระค่าสมาชิก
          </h1>

          <p className="text-[16px] lg:text-[22px] text-left mt-[22px] font-normal">
            อัตราค่าสมัครสมาชิกพยาบาลตลอดชีพ <br />
            – สมัครภายในปี พ.ศ. ที่สำเร็จการศึกษา 2,500 บาท <br />–
            กรณีไม่สมัครภายในปี พ.ศ. ที่สำเร็จการศึกษา 3,500 บาท
          </p>

          <p className="text-[16px] lg:text-[22px] text-left mt-[22px] text-red">
            **กรุณา ชำระค่าสมัครสมาชิก ก่อนเริ่มกรอกข้อมูล
          </p>

          <div className="font-normal text-[20px] lg:text-[24px] text-left py-[20px] px-[32px] rounded-[8px] border-[1px] border-lightGray mt-[15px] max-w-[500px]">
            ธนาคารออมสิน สาขาถนนท่าแฉลบ <br />
            ชื่อบัญชี นางขนิษฐา เมฆกมล <br />
            <span className="flex items-center gap-[10px]">
              เลขที่บัญชี{" "}
              <span
                onClick={() => CopyAccNum("020001420395")}
                className="cursor-pointer text-gold flex flex-row items-center gap-[20px] hover:underline"
              >
                020001420395{" "}
                <FontAwesomeIcon
                  icon={faClone}
                  className="text-[22px] cursor-pointer"
                />
              </span>
            </span>
          </div>

          <p className="text-[16px] lg:text-[22px] text-left mt-[22px] font-normal">
            สอบถามข้อมูลเพิ่มเติมนายทะเบียน สมาคมพยาบาลฯ <br />
            สำนักงานสาขาภาคตะวันออก <br />
            คุณขนิษฐา เมฆกมล โทร.03-932-5781 ต่อ 1401
          </p>

          <div className="mt-[66px] section">
            <CustomButton
              text={"สมัครสมาชิก ภาคตะวันออก"}
              fit
              onClick={() => setClickRegis(true)}
            />
          </div>
        </div>
      </div>
    );
  }

  function RegisterForm() {
    return (
      <div>
        <h1 className="text-[24px] lg:text-[28px] text-center">
          ข้อมูลผู้สมัคร
        </h1>

        <div className="mt-[46px] flex flex-col xl:grid xl:grid-cols-4 gap-[22px]">
          <div className="col-span-1 rounded-[8px] border-dashed border-[1px] border-lightGray bg-lightBg px-[25px] py-[36px] h-inherit xl:min-h-[395px] section gap-[30px] flex-col">
            <p className="text-[20px] lg:text-[22px] text-center text-darkGray">
              เลือกรูปภาพ สำหรับการสมัครสมาชิก
            </p>
            <div>
              <div
                onClick={() => document.getElementById("fileInput").click()}
                className="cursor-pointer flex items-center gap-[12px] py-[7px] px-[35px] rounded-[8px] border-[1px] border-darkGray"
              >
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  className="text-[24px] cursor-pointer text-darkGray"
                />
                <p className="text-[20px] lg:text-[22px] text-center text-darkGray">
                  Browse File
                </p>
              </div>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
            </div>
          </div>
          <div className="col-span-3 flex flex-col gap-[16px]">
            <div className="flex flex-col items-start gap-[5px]">
              <h1 className="text-[16px] lg:text-[18px]">
                สังกัดภาค<span className="text-gold">*</span>{" "}
                ตามสถาบันที่สำเร็จการศึกษาพยาบาล (ปริญญาตรี)
              </h1>
              <select
                name="ordering"
                className="w-full lg:min-w-[300px] font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]"
                value={sort}
                onChange={(event) => setSort(event.target.value)}
              >
                <option value="desc">
                  {lang === "th" ? "ล่าสุด - เก่าสุด" : "Latest to Oldest"}
                </option>
                <option value="asc">
                  {lang === "th" ? "เก่าสุด - ล่าสุด" : "Oldest to Lastest"}
                </option>
              </select>
            </div>

            <div className="flex flex-col items-start gap-[5px]">
              <h1 className="text-[16px] lg:text-[18px]">เลขที่บัตรประชาชน</h1>
              <input className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
            </div>

            <div className="flex flex-col lg:flex-row items-start gap-[12px] w-full">
              <div className="flex flex-col items-start gap-[5px] w-full">
                <h1 className="text-[16px] lg:text-[18px]">คำนำหน้า</h1>
                <select
                  name="ordering"
                  className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]"
                  value={sort}
                  onChange={(event) => setSort(event.target.value)}
                >
                  <option value="desc">
                    {lang === "th" ? "ล่าสุด - เก่าสุด" : "Latest to Oldest"}
                  </option>
                  <option value="asc">
                    {lang === "th" ? "เก่าสุด - ล่าสุด" : "Oldest to Lastest"}
                  </option>
                </select>
              </div>

              <div className="flex flex-col items-start gap-[5px] w-full">
                <h1 className="text-[16px] lg:text-[18px]">ชื่อ</h1>
                <input className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
              </div>

              <div className="flex flex-col items-start gap-[5px] w-full">
                <h1 className="text-[16px] lg:text-[18px]">นามสกุล</h1>
                <input className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-start gap-[12px] w-full">
              <div className="flex flex-col items-start gap-[5px] w-full">
                <h1 className="text-[16px] lg:text-[18px]">
                  คำนำหน้า (ภาษาอังกฤษ)
                </h1>
                <select
                  name="ordering"
                  className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]"
                  value={sort}
                  onChange={(event) => setSort(event.target.value)}
                >
                  <option value="desc">
                    {lang === "th" ? "ล่าสุด - เก่าสุด" : "Latest to Oldest"}
                  </option>
                  <option value="asc">
                    {lang === "th" ? "เก่าสุด - ล่าสุด" : "Oldest to Lastest"}
                  </option>
                </select>
              </div>

              <div className="flex flex-col items-start gap-[5px] w-full">
                <h1 className="text-[16px] lg:text-[18px]">
                  ชื่อ (EN-ภาษาอังกฤษ)
                </h1>
                <input className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
              </div>

              <div className="flex flex-col items-start gap-[5px] w-full">
                <h1 className="text-[16px] lg:text-[18px]">
                  นามสกุล (EN-ภาษาอังกฤษ)
                </h1>
                <input className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
              </div>
            </div>

            <div className="flex flex-col items-start gap-[5px]">
              <h1 className="text-[16px] lg:text-[18px]">วัน-เดือน-ปีเกิด</h1>
              <div className="date-picker-register date-picker w-full">
                <Datepicker
                  asSingle={true}
                  useRange={false}
                  primaryColor={"#B7B7B7"}
                  value={date}
                  onChange={(newValue) => setDate(newValue)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[22px] flex flex-col gap-[13px]">
          <div className="flex flex-col lg:flex-row items-start gap-[25px] w-full">
            <div className="flex flex-col items-start gap-[5px] w-full lg:w-fit">
              <h1 className="text-[16px] lg:text-[18px]">
                ระดับการศึกษาสูงสุด
              </h1>
              <select
                name="ordering"
                className="w-full lg:w-[304px] font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]"
                value={sort}
                onChange={(event) => setSort(event.target.value)}
              >
                <option value="desc">
                  {lang === "th" ? "ล่าสุด - เก่าสุด" : "Latest to Oldest"}
                </option>
                <option value="asc">
                  {lang === "th" ? "เก่าสุด - ล่าสุด" : "Oldest to Lastest"}
                </option>
              </select>
            </div>

            <div className="flex flex-col items-start gap-[5px] w-full">
              <h1 className="text-[16px] lg:text-[18px]">สถานศึกษา</h1>
              <input className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
            </div>
          </div>

          <div className="flex flex-col items-start gap-[5px] w-full">
            <h1 className="text-[16px] lg:text-[18px]">
              สังกัด{" "}
              <span className="text-lightGray">: สังกัดปัจจุบันที่ทำงาน</span>
            </h1>
            <input className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
          </div>

          <div className="flex flex-col lg:flex-row items-start gap-[25px] w-full lg:w-fit">
            <div className="flex flex-col items-start gap-[5px] w-full">
              <h1 className="text-[16px] lg:text-[18px]">ที่อยู่ปัจจุบัน</h1>
              <input className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
            </div>

            <div className="flex flex-col items-start gap-[5px] w-full lg:w-fit">
              <h1 className="text-[16px] lg:text-[18px]">จังหวัด</h1>
              <input className="lg:w-[300px] w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
            </div>
          </div>
        </div>

        <div className="mt-[10px]">
          <h1 className="font-bold text-[18px] lg:text-[20px] text-left">
            ช่องทางการติดต่อ
          </h1>

          <div className="flex items-start flex-col lg:flex-row gap-[25px] w-full mt-[5px]">
            <div className="flex flex-col items-start gap-[5px] w-full lg:w-fit">
              <h1 className="text-[16px] lg:text-[18px]">
                เบอร์โทรศัพท์ ที่ติดต่อได้สะดวก
              </h1>
              <input className="lg:w-[305px] w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
            </div>

            <div className="flex flex-col items-start gap-[5px] w-full">
              <h1 className="text-[16px] lg:text-[18px]">E-mail</h1>
              <input className="w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
            </div>
          </div>
        </div>

        <div className="mt-[24px]">
          <h1 className="text-center text-[24px] lg:text-[28px]">
            ข้อมูลผู้ใช้งานระบบ
          </h1>
          <div className="section flex-col gap-[25px] w-full mt-[22px]">
            <div className="flex flex-col items-start gap-[5px]">
              <h1 className="text-[16px] lg:text-[18px]">Password</h1>
              <input className="lg:w-[400px] w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
            </div>

            <div className="flex flex-col items-start gap-[5px]">
              <h1 className="text-[16px] lg:text-[18px]">Re-enter Password</h1>
              <input className="lg:w-[400px] w-full font-normal text-[16px] lg:text-[18px] py-[11px] px-[23px] border-[1px] border-lightGray rounded-[8px]" />
            </div>
          </div>
        </div>

        <div className="mt-[24px]">
          <h1 className="text-center text-[24px] lg:text-[28px]">
            หลักฐานการชำระค่าสมาชิก
          </h1>
          <div className="flex-col md:flex-row mt-[25px] rounded-[8px] border-dashed border-[1px] border-lightGray bg-lightBg px-[25px] py-[36px] section gap-[30px]">
            <div>
              <div
                onClick={() => document.getElementById("fileInput").click()}
                className="cursor-pointer flex items-center gap-[12px] py-[7px] px-[35px] rounded-[8px] border-[1px] border-darkGray"
              >
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  className="text-[24px] cursor-pointer text-darkGray"
                />
                <p className="text-[20px] lg:text-[22px] text-center text-darkGray">
                  Browse
                </p>
              </div>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
            </div>
            <p className="text-[20px] lg:text-[22px] text-center text-darkGray">
              Upload สลิปชำระค่าสมัครสมาชิก
            </p>
          </div>
        </div>

        <div className="mt-[50px] section">
          <CustomButton
            text={"ยืนยันข้อมูลสมาชิก ภาคตะวันออก"}
            fit
            onClick={() => setClickRegis(true)}
          />
        </div>
      </div>
    );
  }

  return (
    <div id="member-register" className="pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={"สมัครสมาชิกสมาคมพยาบาลฯ สาขาภาคตะวันออก"}
        text_en={"Member Registration"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* บริการ */}
          <div className="mt-[42px] mb-[85px]">
            {!clickRegis ? <Regulation /> : <RegisterForm />}
          </div>
        </div>
      </div>
    </div>
  );
}
