import React, { useEffect } from "react";
// import ServiceService from "../../services/service";
// import BannerService from "../../services/banner";
import { useParams } from "react-router-dom";
import TextBanner from "../../components/banner/TextBanner";
// import StaffCard from "../../components/card/StaffCard";

export default function Member() {
  let { lang } = useParams();

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // getServiceData();
    // getServiceBanner();
  }, []);

  //   const getServiceData = async () => {
  //     try {
  //       let res = await ServiceService.getService();
  //       setServiceData(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  //   const getServiceBanner = async () => {
  //     try {
  //       let res = await BannerService.getBanner(1);
  //       setServiceBanner(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  const bannerText = [
    { name: "About us", href: "/abouts" },
    { name: "Member Welfare Regualations", href: "/abouts/member" },
  ];

  return (
    <div id="service" className="pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={"ระเบียบสวัสดิการสมาชิก"}
        text_en={"Member Welfare Regulations"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          <div className="mt-[75px] mb-[300px]"></div>
        </div>
      </div>
    </div>
  );
}
