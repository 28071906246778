// eslint-disable-next-line
export default new (class MemberModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.no = data.attributes.no || "";
    model.prefix = data.attributes.prefix || "";
    model.name = data.attributes.name || "";
    model.surname = data.attributes.surname || "";
    model.member_id = data.attributes.member_id || "";
    model.graduated_from = data.attributes.graduated_from || "";
    model.current_work = data.attributes.current_work || "";

    return model;
  }
})();
