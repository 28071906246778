import React, { useEffect } from "react";
// import ServiceService from "../../services/service";
// import BannerService from "../../services/banner";
import { useParams } from "react-router-dom";
import TextBanner from "../../components/banner/TextBanner";
import StaffCard from "../../components/card/StaffCard";

export default function Organization() {
  let { lang } = useParams();

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // getServiceData();
    // getServiceBanner();
  }, []);

  //   const getServiceData = async () => {
  //     try {
  //       let res = await ServiceService.getService();
  //       setServiceData(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  //   const getServiceBanner = async () => {
  //     try {
  //       let res = await BannerService.getBanner(1);
  //       setServiceBanner(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  const bannerText = [
    { name: "About us", href: "/abouts" },
    { name: "Broading", href: "/abouts/broading" },
  ];

  return (
    <div id="service" className="pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={"โครงสร้างคณะกรรมการ"}
        text_en={"Broading"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* บริการ */}
          <div className="mt-[75px] mb-[300px]">
            <p className="text-[26px] lg:text-[34px] text-brown">
              รายนามคณะกรรมการอำนวยการสมาคมพยาบาลแห่งประเทศไทยฯ <br />
              สาขาภาคตะวันออก วาระปี 2565 - 2567
            </p>

            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-[24px] mt-[45px]">
              {[...Array(8)].map((item, i) => (
                <div key={i}>
                  <StaffCard data={item} is_staff={true} lang={lang} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
