import React from "react";
import timeFunction from "../../functions/getTime";

export default function VerticleCard(props) {
  return (
    <div className="w-full rounded-[12px] border-[1px] border-lightGray2">
      <div
        className="!bg-cover !bg-center h-[170px] bg-lightGray rounded-t-[12px]"
        onClick={() => {
          if (props.page === "newsAndActivity/activity")
            window.open(
              `${process.env.REACT_APP_WEB_URL}/${props.lang}/${props.page}/${props.data.slug}`,
              "_self"
            );
        }}
        style={{
          background:
            props.data &&
            props.data.image[0] &&
            `url(${process.env.REACT_APP_API_URL}${props.data.image[0].attributes.url}), #D9D9D9`,
        }}
      />
      <div className="p-[20px]">
        <div className="flex gap-[15px]">
          {props.data?.date && (
            <div>
              <h1 className="text-[38px] xl:text-[48px] font-medium leading-[57px]">
                {
                  timeFunction.getActivityTime(
                    props.lang,
                    props.data.date.startDate
                  )[0]
                }
              </h1>
              <h1 className="text-[28px] xl:text-[36px] font-medium mt-[-8px]">
                {
                  timeFunction.getActivityTime(
                    props.lang,
                    props.data.date.startDate
                  )[1]
                }
              </h1>
              <p className="text-[12px] xl:text-[14px] font-medium leading-[19px] whitespace-nowrap">
                {
                  timeFunction.getActivityTime(
                    props.lang,
                    props.data.date.startDate
                  )[3]
                }
              </p>
            </div>
          )}
          <div>
            {props.data?.title && (
              <p
                className="text-[14px] xl:text-[18px] text-left cursor-pointer"
                onClick={() => {
                  if (props.page === "newsAndActivity/activity")
                    window.open(
                      `${process.env.REACT_APP_WEB_URL}/${props.lang}/${props.page}/${props.data.slug}`,
                      "_self"
                    );
                }}
              >
                {props.lang === "th"
                  ? props.data.title.event_TH
                  : props.data.title.event_EN}
              </p>
            )}
            {props.data?.content && (
              <p className="sarabun font-normal text-[12px] xl:text-[14px] mt-[10px] text-left line-clamp-4">
                {props.lang === "th"
                  ? props.data.content.detail_TH
                  : props.data.content.detail_EN}
              </p>
            )}
          </div>
        </div>
        <p
          className="text-right text-red text-[12px] xl:text-[14px] font-bold mt-[10px] cursor-pointer"
          onClick={() => {
            if (props.page === "newsAndActivity/activity")
              window.open(
                `${process.env.REACT_APP_WEB_URL}/${props.lang}/${props.page}/${props.data.slug}`,
                "_self"
              );
          }}
        >
          {props.lang === "th" ? "อ่านเพิ่มเติม" : "Read More"}
        </p>
      </div>
    </div>
  );
}
