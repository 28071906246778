import React, { useEffect } from "react";
// import ImageCard from "../../components/card/ImageCard";
import AllButton from "../../components/button/AllButton";
import { Link, useParams } from "react-router-dom";
// import { useState } from "react";
// import timeFunction from "../../functions/getTime";
import TextBanner from "../../components/banner/TextBanner";

export default function AssociationDetail() {
  // let [galleryData, setGalleryData] = useState([]);
  // let [anotherGalleryData, setAnotherGalleryData] = useState([]);
  let { lang } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    // getGalleryData();
    // getAnotherGalleryData();
    // eslint-disable-next-line
  }, []);

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  // const getGalleryData = async () => {
  //   try {
  //     let res = await GalleryService.getGalleryBySlug(`${slug}`);

  //     if (res[0]) {
  //       setGalleryData(res[0]);
  //     } else {
  //       window.open(
  //         `${process.env.REACT_APP_WEB_URL}/${lang}/gallery`,
  //         "_self"
  //       );
  //     }
  //   } catch {
  //     console.error();
  //   }
  // };

  // const getAnotherGalleryData = async () => {
  //   try {
  //     let res = await GalleryService.getAnotherGallery(`${slug}`);

  //     if (res) {
  //       setAnotherGalleryData(res);
  //     }
  //   } catch {
  //     console.error();
  //   }
  // };

  const bannerText = () => {
    return [
      { name: "Works and Honor", href: "/honor" },
      {
        name: "Activities",
        href: "/honor/activities",
      },
      {
        name: "กิจกรรมอบรมพยาบาล 2565",
      },
    ];
  };

  return (
    <div className="service-info pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={null}
        text_en={"Documents"}
        // banner={newsBanner.banner}
        bannerText={bannerText()}
        lang={lang}
      />
      <div className="section">
        <div className="page-container">
          {/* บริการ แยก */}
          <div className="mt-[54px] mb-[300px]">
            <h1 className="text-[28px] xl:text-[32px] text-left mt-[25px]">
              กิจกรรมอบรม
            </h1>

            <div className="mt-[10px] flex items-center gap-[30px]">
              <p className="text-[16px] xl:text-[18px] font-medium text-left">
                {/* {timeFunction.getTime(galleryData.date, lang)} */}
              </p>
              <div className="flex justify-center items-center gap-[12px]">
                <div>
                  <div className="rounded-full w-[10px] h-[10px] xl:w-[14px] xl:h-[14px] bg-lightGray" />
                </div>
                <p className="text-[16px] xl:text-[18px] font-medium text-left">
                  ฝ่ายพยาบาล
                </p>
              </div>
            </div>

            <div className="mt-[10px] flex gap-[16px]">
              <p className="text-[12px] xl:text-[14px] font-medium text-left text-blue">
                {[...Array(4)].map((item, i) => (
                  <span key={i}>
                    <>
                      <span
                        className="cursor-pointer text-blue hover:underline"
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_WEB_URL}/${lang}/search/${item.tag}`,
                            "_self"
                          );
                        }}
                      >
                        #test
                      </span>
                      {i + 1 < 4 && ", "}
                      {/* {i + 1 < galleryData?.tag?.length && ", "} */}
                    </>
                  </span>
                ))}
              </p>
            </div>

            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[35px] mt-[55px] mb-[120px]">
                {/* {galleryData?.image?.image?.data.map((item, i) => (
                  <span key={i}>
                    <div
                      className="w-full rounded-[12px] bg-lightGray h-[240px] !bg-cover !bg-center"
                      style={
                        item
                          ? {
                              background: `url(${process.env.REACT_APP_API_URL}${item.attributes.url})`,
                            }
                          : { background: `#D9D9D9` }
                      }
                    />
                  </span>
                ))} */}
              </div>

              <hr className="my-[36px] h-[2px] w-full bg-darkGray" />

              <div>
                <div className="flex justify-between">
                  <h1 className="text-[28px] xl:text-[32px] text-left">
                    ประมวลภาพกิจกรรมอื่นๆ
                  </h1>
                  <Link to={`/${lang}/gallery`}>
                    <AllButton lang={lang} />
                  </Link>
                </div>

                {/* <div
                  id="image-activity"
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:flex-row gap-[30px] mt-[68px]"
                >
                  {anotherGalleryData &&
                    anotherGalleryData
                      .slice(0, 3)
                      .map((item, i) => (
                        <ImageCard data={item} key={i} lang={lang} />
                      ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
