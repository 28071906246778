import React, { useEffect } from "react";
// import ServiceService from "../../services/service";
// import BannerService from "../../services/banner";
import { useParams } from "react-router-dom";
import TextBanner from "../../components/banner/TextBanner";

// import DateParagraph from "../../components/content/DateParagraph";
// import BulletParagraph from "../../components/content/BulletParagraph";

export default function Objective() {
  let { lang } = useParams();

  //return lang function
  useEffect(() => {
    if (lang !== "th" && lang !== "en") {
      window.open(`${process.env.REACT_APP_WEB_URL}/th`, "_self");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // getServiceData();
    // getServiceBanner();
  }, []);

  //   const getServiceData = async () => {
  //     try {
  //       let res = await ServiceService.getService();
  //       setServiceData(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  //   const getServiceBanner = async () => {
  //     try {
  //       let res = await BannerService.getBanner(1);
  //       setServiceBanner(res);
  //     } catch {
  //       console.error();
  //     }
  //   };

  const bannerText = [
    { name: "About us", href: "/abouts" },
    { name: "Objectives", href: "/abouts/objective" },
  ];

  return (
    <div id="service" className="pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={"วัตถุประสงค์"}
        text_en={"Objectives"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* บริการ */}
          <div className="mt-[75px] mb-[300px]">
            {/* {aboutUsData &&
              aboutUsData.map((item, i) => (
                <div key={i}>
                  <h1 className="text-[28px] xl:text-[32px] text-left my-[50px] font-bold">
                    {lang === "th" ? item.title_th : item.title_en}
                  </h1>
                  {item.type === "global.detail" && (
                    <p className="text-[18px] xl:text-[22px] text-left whitespace-pre-line">
                      {lang === "th"
                        ? item.content[0].detail_TH
                        : item.content[0].detail_EN}
                    </p>
                  )}

                  {item.type === "about.table" && (
                    <div className="flex flex-col gap-[58px] text-left font-medium">
                      {item.content &&
                        item.content.map((data, i) => (
                          <div key={i}>
                            {DateParagraph(
                              lang === "th" ? data.left_TH : data.left_EN,
                              lang === "th" ? data.right_TH : data.right_EN
                            )}
                          </div>
                        ))}
                    </div>
                  )}

                  {item.type === "about.bullet" && (
                    <div className="flex flex-col gap-[32px] text-left font-medium">
                      <p className="text-[18px] xl:text-[22px]">
                        {lang === "th"
                          ? item.content[0].detail_TH
                          : item.content[0].detail_EN}
                      </p>
                      {item.content &&
                        item.content.map((data, i) => (
                          <div key={i}>
                            {BulletParagraph(
                              lang === "th" ? data.title_TH : data.title_EN,
                              lang === "th" ? data.detail_TH : data.detail_EN
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              ))} */}
          </div>
        </div>
      </div>
    </div>
  );
}
