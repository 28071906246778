// eslint-disable-next-line
export default new (class ViewsModel {
  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.page_name = data.attributes.page_name || "";
    model.today_views = data.attributes.today_views || 0;
    model.this_month_views = data.attributes.this_month_views || 0;
    model.all_time_views = data.attributes.all_time_views || 0;

    return model;
  }
})();
