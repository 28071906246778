import React, { useState, Fragment } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const data = [
  {
    title: "ชื่อทุนการศึกษา",
    detail: "เปิดรับสมัคร 1 มกราคม - 31 มีนาคม 2566",
    content: "Lorem ipsum dolor sit amet, consectetur ",
  },
  {
    title: "ชื่อทุนการศึกษา",
    detail: "เปิดรับสมัคร 1 มกราคม - 31 มีนาคม 2566",
    content: "Lorem ipsum dolor sit amet, consectetur ",
  },
  {
    title: "ชื่อทุนการศึกษา",
    detail: "เปิดรับสมัคร 1 มกราคม - 31 มีนาคม 2566",
    content: "Lorem ipsum dolor sit amet, consectetur ",
  },
];

export default function FAQ(props) {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  return (
    <div className="border-[1px] rounded-[12px] py-[14px] px-[20px] border-lightGray2">
      <Fragment>
        {data &&
          data.map((item, i) => (
            <Accordion key={i} open={open === i + 1} animate={customAnimation}>
              <AccordionHeader
                onClick={() => handleOpen(i + 1)}
                className="font-medium text-black text-left ibm-plex-san faq-title flex flex-col items-start"
              >
                <h1 className="!text-brown text-[18px] xl:text-[20px] ">
                  {props.lang === "th" ? item.title : item.title}
                </h1>
                <h1 className="text-[16px] xl:text-[18px]">
                  {props.lang === "th" ? item.detail : item.detail}
                </h1>
              </AccordionHeader>
              <AccordionBody className="text-left ibm-plex-san text-[14px] xl:text-[18px] faq-body font-medium">
                {props.lang === "th" ? item.content : item.content}
              </AccordionBody>
            </Accordion>
          ))}
      </Fragment>
    </div>
  );
}
