import React from "react";

export default function StaffHorizontalCard(props) {
  return (
    <div className="flex flex-col lg:flex-row rounded-[12px] border-[1px] border-gold w-full">
      <div className="rounded-t-[12px] lg:rounded-tr-[0px] lg:rounded-l-[12px] bg-lightGray w-full lg:w-[40%] min-h-[200px] lg:min-h-inherit h-full" />
      <div className="flex flex-col gap-[20px] lg:gap-[36px] py-[10px] px-[10px] md:py-[27px] md:px-[32px] w-full">
        <p className="text-[20px] lg:text-[22px] text-left">1.</p>
        <div className="flex flex-col lg:grid lg:grid-cols-2 gap-[30px] pb-[40px]">
          <div>
            <p className="text-[20px] lg:text-[22px] text-left">
              ชื่อจริง นามสกุล
            </p>
            <p className="text-[20px] lg:text-[22px] text-left">
              Name Family-name
            </p>
          </div>
          <div className="flex flex-col gap-[5px] items-start">
            <p className="font-semibold text-[20px] lg:text-[22px] text-darkGray text-left">
              นายกสมาคมพยาบาลฯ <br />
              สาขาภาคตะวันออก
            </p>
            <p className="text-[16px] lg:text-[18px] text-darkGray">
              พ.ศ. 2474 - 2476
            </p>
            <p className="text-[16px] lg:text-[18px] text-darkGray">
              พ.ศ. 2474 - 2476
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
